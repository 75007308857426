
<template>
  <div class="content-body">
    <!-- 遍历电影列表 -->
    <div class="content-list" v-for="(item, index) in recLatest" :key="index">
      <div class="content-img">
        <a
          :href="YS.returnURL({ path: '/play', query: { video_id: item.id } }, true)"
          :title="item.title"
          target="_blank"
        >
          <img
            :onerror="
              ub_index == 2
                ? $store.state.defaultimg_bo
                : ub_index == 3
                ? $store.state.defaultimg_u
                : $store.state.defaultimg
            "
            class="bodyimg"
            :src="item.pic ? item.pic : ''"
            alt="视频封面"
            :class="item.picLoad ? 'anim' : ''"
          />
        </a>
        <!-- :onerror="YS.noneimg" -->
        <!-- @click="YS.routetogo({ path: '/play', query: { video_id: item.id } })" -->
        <!-- 评分 -->
        <div class="latest_score" v-if="item.score">{{ item.score.toFixed(1) }}</div>
        <div class="ci-nodes">
          <p class="cin-title" :title="item.title">{{ item.title || '无名称' }}</p>
          <div class="cin-nodes">
            <a v-show="item.year">{{ item.year }}</a>
            <a v-show="$store.state.menudist[item.category_id]">
              {{ $store.state.menudist[item.category_id] }}
            </a>
            <a v-show="item.region">{{ item.region }}</a>
            <a v-show="item.language">{{ item.language }}</a>
          </div>
          <div class="cin-introduction" :title="item.description">
            <span>{{ item.description }}</span>
          </div>
          <div class="cin-watch">
            <a
              :href="YS.returnURL({ path: '/play', query: { video_id: item.id } }, true)"
              :title="item.title"
              target="_blank"
              class="cw-button"
              @click="YS.routetogo({ path: '/play', query: { video_id: item.id } }, true)"
            >
              <span class="to_play">立即观看</span>
              <img class="cwb_img" src="../assets/img/homeplay.png" alt="前往观看" />
            </a>
            <div class="cw_right">
              <!-- 收藏 -->
              <div
                class="film_list-1"
                @click="clifavorite(item.in_favorite, item.id, index, item.parent_category_id)"
              >
                <div class="f_i-4l" :class="item.in_favorite ? 'f_i-4lat-act' : 'f_i-4lat'"></div>
                <span>收藏</span>
              </div>
              <div class="cw-num">
                <img height="12" width="11" src="../assets/img/renqi.png" alt="收藏" />
                <span>{{ item.hits }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 方框下内容 -->
      <div class="content-nodes">
        <a
          class="cl-t"
          :title="item.title"
          :href="YS.returnURL({ path: '/play', query: { video_id: item.id } }, true)"
          target="_blank"
        >
          {{ item.title }}
        </a>
        <!-- <p :title="item.remark" class="cl-n">{{ item.remark }}</p> -->
        <p
          class="cl-n"
          :title="
            '更新至第' + item.last_fragment_symbol + (item.parent_category_id == 102 ? '期' : '集')
          "
          v-show="item.state == '更新中' && item.multiple_fragment && item.last_fragment_symbol"
        >
          更新至第{{ item.last_fragment_symbol }}{{ item.parent_category_id == 102 ? '期' : '集' }}
        </p>
        <p
          class="cl-n"
          :title="item.last_fragment_symbol + (item.parent_category_id == 102 ? '期' : '集') + '全'"
          v-show="item.state == '已完结' && item.multiple_fragment && item.last_fragment_symbol"
        >
          {{ item.last_fragment_symbol }}{{ item.parent_category_id == 102 ? '期' : '集' }}全
        </p>
        <p class="cl-n" :title="item.description" v-show="!item.multiple_fragment">
          {{ item.description }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
let that;
that;
export default {
  name: 'Latest',
  data() {
    return {
      ub_index: 1,
      recLatest_clone: [],
      defaultimg: 'this.src="' + require('../assets/img/leave_vip.png') + '"',
    };
  },
  props: ['recLatest', 'recID'],
  created() {
    that = this;
    that;
    this.ub_index = window.g.ub_index;
    // that.$parent.video_rank_list[that.recID].forEach((e, i) => {
    //   // that.$parent.video_rank_list[that.recID];
    //   this.loadOver(e.pic, i);
    //   let data = {};
    //   data.list = that.recID;
    //   data.index = i;
    //   // this.$emit('changePic', data);
    //   that.$parent.video_rank_list[that.recID][i].pic = '';
    // });
    // that.recLatest.forEach((e, i) => {
    //   that.recLatest_clone[i] = {};
    //   Object.keys(e).forEach((ele) => {
    //     that.recLatest_clone[i][ele] = that.recLatest[i][ele];
    //   });
    // });
    // that.recLatest_clone;
  },
  methods: {
    //用于图片加载完毕替换图片
    // loadOver(pic, i) {
    //   var newImg = new Image();
    //   // newImg.src = require('../assets/img/fil_b.png');
    //   newImg.src = pic;
    //   newImg.onload = () => {
    //     // return true;
    //     that.$parent.video_rank_list[that.recID][i].pic = newImg.src;
    //     that.$parent.video_rank_list[that.recID][i].picLoad = true;
    //   };
    //   // if (!that.$refs[src]) return;
    //   // let toTop = that.$refs[src].offsetTop;
    //   // console.log(toTop);
    //   // let toTop = src
    // },
    // 点击收藏视频按钮
    clifavorite(in_favorite, id, index, pid) {
      if (that.$store.state.islogin) {
        //已登录
        that.gofavorite(in_favorite, id, index, pid);
      } else {
        //未登录
        that.$store.commit('addloginBefore', () => {
          that.gofavorite(in_favorite, id, index, pid);
        });
        that.YS.gotoVip({}, 'open', 'loginReg'); //打开弹窗
        that.$parent.$refs.top.TOvercode(); //获取验证码
      }
    },
    async gofavorite(in_favorite, id, index, pid) {
      try {
        if (in_favorite) {
          // 已经收藏的情况
          //发送取消收藏

          await that.api.user.api_videodeleteFavorite({ video_id: id });
          that.$parent.video_latest_list['i' + pid][index].in_favorite = 0;
        } else {
          // 未收藏的情况
          //发送收藏视频
          await that.api.user.api_videofavorite({ video_id: id });
          that.$parent.video_latest_list['i' + pid][index].in_favorite = 1;
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
